import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object().shape({
      member_id: Yup.string().required(),
  }).required(),
}).required()

type Request = Yup.InferType<typeof RequestSchema>

export const isValid = async (params: Request) => await RequestSchema.isValid(params)
export const validate = async (params: Request) => RequestSchema.validate(params)
export const url = () => `/v1/member-services`
// TODO: remove once configBuilder is typed
//@ts-ignore
export const execute = async (params: Request) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
