import * as Yup from 'yup'
import configBuilder from '../config-builder'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import { SELF_CARE_SERVICE_LINE_TYPES } from 'types/explore/selfCares'
const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  params: Yup.object().shape({
    randomize: Yup.boolean().required(),
    randomize_count: Yup.string().required(),
    service_line_type: Yup.string().oneOf(Object.values(SELF_CARE_SERVICE_LINE_TYPES)),
    only_journeys: Yup.boolean().notRequired().nullable(),
  }),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => await RequestSchema.validate(params)
export const url = () => '/v1/self-cares'
export const execute = async (params) => {
  return api.get(url(params), configBuilder(params)).catch(catchApiFailure)
}
