import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import withForm from 'hoc/withForm'
import { isEmpty } from 'lodash'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import TestIds from 'util/TestIds'
import * as MemberSelectors from 'redux/selectors/members'
import * as ServiceSelectors from 'redux/selectors/services'
import VisitScheduleType from 'util/visit-schedule-types'
import VisitTypes, {
  DisplayVisitType,
  OutsideTheUSVisitTypes,
  ServiceTypes,
  VisitTypeValues,
  orderForService,
  routingEnabledServices,
  minorSelfConsentServices,
  ServiceStatusTypes,
} from 'util/visit-types'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_PENDING_VISIT, createWithQuery } from 'routes/paths'
import ServiceInfo from 'components/visit-creation/ServiceInfo'
import { AnyObjectSchema } from 'yup'
import { Service } from 'types/member/member'
import { MinOption } from 'types/form/Form'
import DisabledServicesSubText from 'components/common/DisabledServicesSubText'
import { useMediaQuery } from 'react-responsive'
import classNames from '@ui-components-3/ui/lib/utils/classNames'
import { putNewVisit } from 'redux/slices/visits'
import PendingVisit from 'types/visit/pendingVisit'
import CrisisResources from 'components/visit-creation/CrisisResources/CrisisResources'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { isLocationOutsideUS } from 'util/visit-location'
import EmptyServicesState from 'components/common/EmptyServicesState'
import RequiredFieldLabel from 'components/form/RequiredFieldLabel'
import { Link } from 'react-router-dom'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import useScheduledVisitsLimit from 'hooks/useScheduledVisitsLimit'
import VisitLimitDialog from '../VisitLimitDialog'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'

import { isTypeFetching } from 'redux/selectors/api'
import FetchTypes from 'util/fetch-types'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { getMemberAge } from 'redux/selectors/members'
import { TcFF } from 'components/feature-flag/flags'
import { useMinorConsent } from 'hooks/useMinorConsent'
import Loading from '@ui-components-3/ui/lib/components/Loading'

type Option = MinOption & {
  age: string
  disabled: boolean
  gender: string
  name: string
}

type ServiceOption = MinOption & { disabled: boolean; order: number }

const serviceSorter = (a: ServiceOption, b: ServiceOption) => {
  if (a.order > b.order) return 1
  return -1
}

const getOptionServiceCardLabel = (option: ServiceOption) => (
  <ServiceInfo type={option.value} disabled={option.disabled} />
)

const getOptionCardValue = (o: Option) => o.value
const isOptionCardDisabled = (option: Option) => option.disabled
const getOptionCardProps = (_: Option) => ({ className: 'bg-white' })

const isNowType = (serviceType: ServiceTypes) =>
  serviceType === VisitTypes.medicalNow || serviceType === VisitTypes.therapyNow

const isScheduledType = (serviceType: ServiceTypes) =>
  serviceType === VisitTypes.medical ||
  serviceType === VisitTypes.therapy ||
  serviceType === VisitTypes.psychiatry ||
  serviceType === VisitTypes.healthCoach

type initialValues = {
  location: string
  memberDob: string
  memberFirstName: string
  memberId: string
  memberLastName: string
  price: number
  reasonForVisit: string
  scheduledType: string
  timezone: string
  visitType: string
}

type NewVisitTypeFormProps = {
  initialValues: initialValues
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: () => void
  values: initialValues
  errors: object
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: (values: { disabled: boolean; leaveFlow: boolean }) => JSX.Element
  positionError: any
  clearPayload: () => void
  pendingScheduledVisits: { [key: string]: string[] }
  userMemberId: string
  pendingVisit: PendingVisit
  isCountryRestricted: boolean
  variant: 'medicalAndHCServices' | 'allServices'
  handleReturnToDashboard: () => void
  fetchVisitCount: (visitType: string) => Promise<{
    success: boolean
    count: number | null
  }>
}

export const NewVisitTypeForm = (props: NewVisitTypeFormProps) => {
  const {
    setFieldValue,
    handleSubmit,
    values,
    isValid,
    initialValues,
    pendingVisit,
    isCountryRestricted,
    variant = 'allServices',
    handleReturnToDashboard,
    fetchVisitCount,
  } = props

  const dispatch = useDispatch()
  const tcMinorConsent = useFeatureFlagToggle('tcMinorConsent')
  const visitHardStopModalRef = useRef<ModalRef>(null)
  const [visitHardStop, setVisitHardStop] = useState(false)
  const nextBtnDisabled = !isValid || visitHardStop
  const isServiceLineRoutingFlagOn = useFeatureFlagToggle('tcServiceLineRouting')
  const tcMinorConsentPhase2 = useFeatureFlagToggle(TcFF.tcMinorConsentPhase2)
  const { getConsentTypeAndStatus } = useMinorConsent(pendingVisit.memberId)
  const isFetchingConsents = useSelector(isTypeFetching(FetchTypes.getMemberConsents))

  const reinitalizePendingVisit = useCallback(
    () =>
      dispatch(
        putNewVisit({
          pendingVisit: {
            memberDob: pendingVisit?.memberDob || '',
            memberFirstName: pendingVisit?.memberFirstName || '',
            memberId: pendingVisit?.memberId || '',
            memberLastName: pendingVisit?.memberLastName || '',
            timezone: pendingVisit?.timezone || '',
            location: pendingVisit?.location || '',
            locationCountry: pendingVisit?.locationCountry || '',
          },
        }),
      ),
    [pendingVisit, dispatch],
  )

  const isMedicalAndHCServices = useMemo(() => variant === 'medicalAndHCServices', [variant])
  const isTwoColumnServices = useMediaQuery({ query: '(max-width: 1348px)' })
  const loggedInMember = useSelector(MemberSelectors.getLoggedInMember)
  const allServices = useSelector(ServiceSelectors.getVisitServices)
  const isFetchingVisitCount = useSelector(isTypeFetching(FetchTypes.getClientVisitCount))
  const memberAge = useSelector(getMemberAge(pendingVisit?.memberId))

  const isOutsideTheUS = isLocationOutsideUS(pendingVisit.locationCountry)

  const { setScheduledVisitType, LimitRescheduleDialog } = useScheduledVisitsLimit(handleReturnToDashboard)

  const services = useMemo(() => {
    if (isCountryRestricted) {
      return []
    }

    let filteredServices = allServices

    if (isMedicalAndHCServices) {
      filteredServices = filteredServices.filter((service) => routingEnabledServices.includes(service.serviceType))
    }

    if (tcMinorConsentPhase2 && !isServiceLineRoutingFlagOn && memberAge < 18 && !isOutsideTheUS) {
      return filteredServices.filter((service) => service?.status !== 'hidden')
    }

    if (tcMinorConsent && memberAge < 18) {
      filteredServices = filteredServices.filter((service) => minorSelfConsentServices.includes(service.serviceType))
    }

    if (isOutsideTheUS) {
      return filteredServices.filter((service) => {
        const serviceType = service.serviceType
        return !VisitTypeValues.includes(serviceType) || OutsideTheUSVisitTypes.includes(serviceType)
      })
    }

    return filteredServices
  }, [
    allServices,
    isCountryRestricted,
    isMedicalAndHCServices,
    isOutsideTheUS,
    isServiceLineRoutingFlagOn,
    memberAge,
    tcMinorConsent,
    tcMinorConsentPhase2,
  ])

  const idPrefix = useId()
  const parentRadioGroupLabelId = idPrefix + 'parent'
  const scheduledRadioGroupLabelId = idPrefix + 'scheduled'
  const onDemandRadioGroupLabelId = idPrefix + 'ondemand'

  const serviceMap = useMemo(() => {
    if (!services) return {}
    return services.reduce((acc: { [key: string]: Service }, service) => {
      acc[service.serviceType] = service
      return acc
    }, {})
  }, [services])

  const isServicesListEmpty = !services?.length

  const hasReachedVisitHardStop = useCallback(
    async (visitType: ServiceTypes) => {
      const { limitMember, hardStop } = serviceMap[visitType]

      if (!hardStop) return
      const { count, success } = await fetchVisitCount(visitType)
      const hasReachedHardStop = limitMember && hardStop && success && count >= limitMember

      setVisitHardStop(hasReachedHardStop)
      return hasReachedHardStop
    },
    [fetchVisitCount, serviceMap],
  )

  const handleServiceSelection = async (visitType: ServiceTypes) => {
    const status = services.find((service) => service.serviceType === visitType).status ?? ServiceStatusTypes.visible

    const isTherapyNowOrHC = visitType === VisitTypes.therapyNow || visitType === VisitTypes.healthCoach

    if (
      tcMinorConsentPhase2 &&
      memberAge < 18 &&
      !isServiceLineRoutingFlagOn &&
      !isTherapyNowOrHC &&
      status === ServiceStatusTypes.conditionally_enabled
    ) {
      const { isParentConsentGranted, isSelfConsentGranted, selfConsentPreApprovalId } = await getConsentTypeAndStatus(
        visitType,
        pendingVisit.location,
      )
      setFieldValue('isParentConsentGranted', !!isParentConsentGranted)
      setFieldValue('isSelfConsentGranted', !!isSelfConsentGranted)
      setFieldValue('selfConsentPreApprovalId', selfConsentPreApprovalId)
    }

    const serviceHardStopEnabled = serviceMap[visitType]?.hardStop

    if (serviceHardStopEnabled) {
      const hasReachedHardStop = await hasReachedVisitHardStop(visitType)
      hasReachedHardStop && visitHardStopModalRef.current?.open()
    } else {
      setVisitHardStop(false)
    }

    if (setScheduledVisitType(visitType)) {
      setFieldValue('visitType', '')
    } else {
      if (initialValues.visitType !== visitType) {
        reinitalizePendingVisit()
        setFieldValue('reasonForVisit', '')
      }
      if (isNowType(visitType)) {
        setFieldValue('scheduledType', VisitScheduleType.now)
      } else if (isScheduledType(visitType)) {
        setFieldValue('scheduledType', VisitScheduleType.scheduled)
      }
      if (status) {
        setFieldValue('status', status)
      }
      setFieldValue('visitType', visitType)
    }
  }

  // @ts-ignore
  const { onDemand, scheduled } = useMemo(() => {
    if (isServicesListEmpty) return []

    const { onDemand, scheduled } = services.reduce(
      (acc: { onDemand: ServiceOption[]; scheduled: ServiceOption[] }, service: Service) => {
        const memberService = loggedInMember?.services?.find((s: Service) => s.serviceId === service.serviceId)
        if (isNowType(service.serviceType)) {
          acc.onDemand.push({
            value: service.serviceType,
            label: DisplayVisitType[service.serviceType],
            disabled: memberService?.active === false,
            order: orderForService(service.serviceType),
          })
        } else {
          acc.scheduled.push({
            value: service.serviceType,
            label: DisplayVisitType[service.serviceType],
            disabled: memberService?.active === false,
            order: orderForService(service.serviceType),
          })
        }
        return acc
      },
      { onDemand: [], scheduled: [] },
    )
    onDemand.sort(serviceSorter)
    scheduled.sort(serviceSorter)
    return { onDemand, scheduled }
  }, [services, loggedInMember, isServicesListEmpty])

  const disabledServices = useMemo(() => {
    if (!onDemand || !scheduled) return []
    return scheduled
      .concat(onDemand)
      .filter((service: ServiceOption) => service.disabled)
      .map((service: ServiceOption) => service.label)
  }, [onDemand, scheduled])

  useEffect(() => {
    if (values.visitType && values.scheduledType && serviceMap) {
      const price = serviceMap[values.visitType]?.serviceFee
      setFieldValue('price', price)
    } else {
      setFieldValue('price', 0)
    }
  }, [values.visitType, values.scheduledType, serviceMap])

  return (
    <>
      <Loading shown={isFetchingConsents} variant="big" />
      <form
        onSubmit={handleSubmit}
        className="flex flex-1 flex-col pb-8"
        data-testid={TestIds.newVisit.view.visitTypeForm}
      >
        {!isCountryRestricted && (
          <>
            {!isServicesListEmpty ? (
              <>
                <RequiredFieldLabel ignoreFieldsLength requiredFieldLabels={['Visit Type']} />
                <h2 className="typography-h5 mb-4 mt-6 text-neutral-800" id={parentRadioGroupLabelId}>
                  What type of visit would you like?*
                </h2>
                <div role="group" className="flex flex-col gap-6" aria-labelledby={parentRadioGroupLabelId}>
                  {!isEmpty(onDemand) && (
                    <div>
                      <h3 className="typography-caption mb-2 text-neutral-500" id={onDemandRadioGroupLabelId}>
                        On-Demand
                      </h3>
                      <div className="md:max-w-[360px]">
                        <RadioCardsGroup
                          aria-labelledby={onDemandRadioGroupLabelId}
                          required={true}
                          columns={1}
                          name="visitType"
                          value={values?.visitType}
                          options={onDemand}
                          getOptionLabel={getOptionServiceCardLabel}
                          getOptionValue={getOptionCardValue}
                          getLabelProps={getOptionCardProps}
                          isOptionDisabled={isOptionCardDisabled}
                          onChange={(e) => handleServiceSelection(e.target.value as ServiceTypes)}
                          getInputProps={(_) => ({
                            className: 'self-start',
                          })}
                        />
                      </div>
                    </div>
                  )}
                  {!isEmpty(scheduled) && (
                    <div className="flex flex-col">
                      <h3 className="typography-caption mb-2 text-neutral-500" id={scheduledRadioGroupLabelId}>
                        Scheduled
                      </h3>
                      <RadioCardsGroup
                        aria-labelledby={scheduledRadioGroupLabelId}
                        required={true}
                        columns={isMedicalAndHCServices ? 1 : 2}
                        name="visitType"
                        value={values?.visitType}
                        options={scheduled}
                        getOptionLabel={getOptionServiceCardLabel}
                        getOptionValue={getOptionCardValue}
                        getLabelProps={getOptionCardProps}
                        isOptionDisabled={isOptionCardDisabled}
                        onChange={(e) => handleServiceSelection(e.target.value as ServiceTypes)}
                        // TODO: Remove class overrides and use column width prop
                        className={
                          isMedicalAndHCServices
                            ? 'md:max-w-[360px]'
                            : '!grid-cols-[repeat(1,minmax(280px,1fr))] sm:!grid-cols-[repeat(1,minmax(360px,1fr))] md:!grid-cols-[repeat(2,minmax(0,360px))]'
                        }
                        getInputProps={(_) => ({
                          className: 'self-start',
                        })}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <EmptyServicesState />
            )}
            {!!disabledServices?.length && (
              <DisabledServicesSubText
                services={disabledServices}
                testid={TestIds.newVisit.label.serviceDisabled}
                className={classNames('2xl:ml-0', isTwoColumnServices ? 'md:ml-[341px] md:p-4' : 'px-0')}
              />
            )}
            {isMedicalAndHCServices && (
              <Link
                to={createWithQuery(ADD_PENDING_VISIT, { step: Steps.visitType, throughStep: Steps.visitType })}
                className="typography-body-m text-primary-600 mt-6 max-w-[360px] text-center font-medium underline"
                aria-label="Click here to navigate to the help center"
              >
                View all services
              </Link>
            )}
          </>
        )}
        {isOutsideTheUS && !isCountryRestricted && !isServicesListEmpty && (
          <p className="typography-small mt-4 text-neutral-800">
            Visit types may be limited due to your current location.{' '}
          </p>
        )}
        {isCountryRestricted && (
          <h5 className="typography-h5 mb-6 text-neutral-800">
            You are currently in a location TimelyCare does not serve
          </h5>
        )}
        {isOutsideTheUS && !isCountryRestricted && <hr className="mb-10 mt-10 h-[1px] w-full bg-neutral-300" />}
        {isOutsideTheUS && (
          <>
            <p className="typography-body mb-6 text-neutral-800">
              If you need crisis support, please choose from the resources below:
            </p>
            <h3 className="typography-caption mb-2 text-neutral-500">Resources</h3>
            <CrisisResources />
          </>
        )}
        {props.children({ disabled: nextBtnDisabled, leaveFlow: isCountryRestricted })}
      </form>
      <VisitLimitDialog
        ref={visitHardStopModalRef}
        serviceType={DisplayVisitType[values.visitType]}
        isLoading={isFetchingVisitCount}
      />
      {LimitRescheduleDialog}
    </>
  )
}

export default withForm(NewVisitTypeForm)
