import Modal, { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { forwardRef } from 'react'
import { DisplayVisitType, OutsideTheUSVisitTypes } from 'util/visit-types'

type VisitTypeNotAllowedDialogProps = {
  serviceType: string
  handleSlrLocationHardStop: () => void
}

const VisitTypeNotAllowedDialog = forwardRef<ModalRef, VisitTypeNotAllowedDialogProps>(
  ({ serviceType, handleSlrLocationHardStop }: VisitTypeNotAllowedDialogProps, ref) => {
    return (
      <Modal
        label={`${serviceType} service not available`}
        size="m"
        ref={ref}
        onClose={() => handleSlrLocationHardStop()}
      >
        <div className="flex flex-col gap-4">
          <p className="typography-body-l text-neutral-800">
            The selected service is not available in your location. Please select one of the following services in order
            to schedule the visit:
          </p>
          {OutsideTheUSVisitTypes.map((visit, idx) => (
            <li className="typography-body-l text-neutral-800" key={`${visit}-${idx}`}>
              {DisplayVisitType[visit]}
            </li>
          ))}
        </div>
      </Modal>
    )
  },
)

export default VisitTypeNotAllowedDialog
