import { Suspense, lazy } from 'react'
import * as paths from './paths'
import withIdleAlert from '../hoc/withIdleAlert'
import { TERMS_CONDITIONS_LINK } from 'util/constants'
import { Redirect } from 'react-router'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import NotFoundPage from '../pages/NotFoundPage'
import AccessibilityDocumentContainer from 'containers/AccessibilityDocument'

const withSuspense = (cb) => {
  const L = lazy(cb)

  return (props) => (
    <Suspense fallback={<Loading variant="big" />}>
      <L {...props} />
    </Suspense>
  )
}

const AccessibilityBranch = withSuspense(() => import('./AccessibilityBranch'))
const AuthBranch = withSuspense(() => import('./AuthBranch'))
const DrawerBranch = withSuspense(() => import('./DrawerBranch'))
const ForbiddenPage = withSuspense(() => import('../pages/ForbiddenPage'))
const UnderagePage = withSuspense(() => import('../pages/UnderagePage'))
const EntryContainer = withSuspense(() => import('../containers/EntryContainer'))
const SSORedirect = withSuspense(() => import('../pages/SSORedirectPage'))
const ForgotPasswordContainer = withSuspense(() => import('../containers/ForgotPasswordContainer'))
const DashboardContainer = withSuspense(() => import('../containers/DashboardContainer'))
const DashboardRedirect = withSuspense(() => import('./DashboardRedirect'))
const RegistrationBranch = withSuspense(() => import('./RegistrationBranch'))
const RegistrationContainer = withSuspense(() => import('../containers/RegistrationContainer'))
const MedicalWizardContainer = withSuspense(() => import('../containers/MedicalWizardContainer/MedicalWizardContainer'))
const DashboardMedicalBranch = withSuspense(() => import('./DashboardMedicalBranch'))
const ExploreBranch = withSuspense(() => import('./ExploreBranch'))
const FamilyHistoryContainer = withSuspense(() => import('../containers/FamilyHistoryContainer'))
const PrimaryCareContainer = withSuspense(() => import('../containers/PrimaryCareContainer'))
const MedicalConditionsContainer = withSuspense(() => import('../containers/MedicalConditionsContainer'))
const MedicalAllergiesContainer = withSuspense(() => import('../containers/MedicalAllergiesContainer'))
const MedicalMedicationsContainer = withSuspense(() => import('../containers/MedicalMedicationsContainer'))
const MedicalGeneralContainer = withSuspense(() => import('../containers/MedicalGeneralContainer'))
const MedicalLifestyleContainer = withSuspense(() => import('../containers/MedicalLifestyleContainer'))
const DashboardPendingVisitBranch = withSuspense(() => import('./DashboardPendingVisitBranch'))
const SuccessBranch = withSuspense(() => import('./SuccessBranch'))
const AddPendingVisitContainer = withSuspense(() => import('../containers/AddPendingVisitContainer'))
const VideoContainer = withSuspense(() => import('../containers/VideoContainer/VideoContainer'))
const ChatVisitPage = withSuspense(() => import('../pages/ChatVisitPage'))
const VideoFeedbackContainer = withSuspense(() => import('../containers/VideoFeedbackContainer/VideoFeedbackContainer'))
const AccountBranch = withSuspense(() => import('./AccountBranch'))
const AccountGeneralContainer = withSuspense(() => import('../containers/AccountGeneralContainer'))
const AccountDependentContainer = withSuspense(() => import('../containers/AccountDependentContainer'))
const SSOContainer = withSuspense(() => import('../containers/SSOContainer'))
const VideoPublicContainer = withSuspense(() => import('../containers/VideoPublicContainer'))
const HelpCenterContainer = withSuspense(() => import('../containers/HelpCenterContainer'))
const HelpCenterBranch = withSuspense(() => import('./HelpCenterBranch'))
const DownloadAppPage = withSuspense(() => import('../pages/DownloadAppPage'))
const UnsubscribeContainer = withSuspense(() => import('../containers/UnsubscribeContainer'))
const CommunityBranch = withSuspense(() => import('./CommunityBranch'))
const CommunityContainer = withSuspense(() => import('../containers/CommunityContainer'))
const ExploreComponentContainer = withSuspense(() => import('../containers/ExploreComponentContainer'))
const SelfCareContainer = withSuspense(() => import('../containers/SelfCareContainer'))
const ExploreCategoryContainer = withSuspense(() => import('../containers/ExploreCategoryContainer'))
const ExploreJourneyContainer = withSuspense(() => import('../containers/ExploreJourneyContainer'))
const AccountSecurityContainer = withSuspense(() => import('../containers/AccountSecurityContainer'))
const AccountBillingContainer = withSuspense(() => import('containers/AccountBillingContainer'))
const AccountGeneralDependentContainer = withSuspense(() => import('../containers/AccountGeneralDependentContainer'))
const VisitSurveyPage = withSuspense(() => import('pages/VisitSurveyPage'))
const AccountServiceContainer = withSuspense(() => import('../containers/AccountServiceContainer'))
const AccountMedicalContainer = withSuspense(() => import('containers/AccountMedicalContainer'))
const PrimaryCareEditContainer = withSuspense(() => import('containers/PrimaryCareEditContainer'))
const MedicalAllergiesEditContainer = withSuspense(() => import('containers/MedicalAllergiesEditContainer'))
const MessagesBranch = withSuspense(() => import('./MessagesBranch'))
const MessagesCenterContainer = withSuspense(() => import('containers/MessagesCenterContainer'))
const TermsAndConditionsPage = withSuspense(() => import('pages/TermsAndConditions'))
const PaymentMethodMobilePage = withSuspense(() => import('pages/PaymentMethodMobile'))
const SuccessPage = withSuspense(() => import('pages/SuccessPage'))
const SuccessCoursesPage = withSuspense(() => import('components/feature-flag/toggles/SuccessCoursesPageToggle'))
const SuccessCoarseDetailsContainer = withSuspense(
  () => import('components/feature-flag/toggles/SuccessCourseDetailsContainerToggle'),
)
const SuccessCourseComponentContainer = withSuspense(
  () => import('components/feature-flag/toggles/SuccessCourseComponentContainerToggle'),
)

const routeTree = [
  {
    component: AuthBranch,
    path: paths.PUBLIC_VIDEO_VISIT,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
    routes: [
      {
        component: VideoPublicContainer,
        exact: true,
        isPrivate: false,
        path: paths.PUBLIC_VIDEO_VISIT,
        crumb: () => ({ isCrumb: false }),
      },
    ],
  },
  {
    component: AccessibilityBranch,
    path: paths.ACCESSIBILITY_DOC,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
    routes: [
      {
        component: AccessibilityDocumentContainer,
        exact: true,
        isPrivate: false,
        path: paths.ACCESSIBILITY_DOC,
        crumb: () => ({ isCrumb: false }),
      },
    ],
  },
  {
    component: AuthBranch,
    path: paths.AUTH,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
    routes: [
      {
        component: EntryContainer,
        exact: true,
        isPrivate: false,
        path: paths.LOGIN,
        crumb: () => ({ isCrumb: false }),
      },
      {
        component: UnderagePage,
        exact: true,
        isPrivate: false,
        path: paths.UNDERAGE,
        crumb: () => ({ isCrumb: false }),
      },
      {
        component: ForbiddenPage,
        exact: true,
        isPrivate: false,
        path: paths.FORBIDDEN,
        crumb: () => ({ isCrumb: false }),
      },
      {
        component: SSOContainer,
        exact: true,
        isPrivate: false,
        path: paths.MOBILE_SSO,
        crumb: () => ({ isCrumb: false }),
      },
      {
        component: ForgotPasswordContainer,
        exact: true,
        isPrivate: false,
        path: paths.FORGOT_PASSWORD,
        crumb: () => ({ isCrumb: false }),
      },

      {
        component: EntryContainer,
        exact: false,
        isPrivate: false,
        path: paths.SSO_REDIRECT,
        crumb: () => ({ isCrumb: false }),
        routes: [
          {
            component: SSORedirect,
            exact: true,
            isPrivate: false,
            path: paths.SSO_REDIRECT,
            crumb: () => ({ isCrumb: false }),
          },
        ],
      },
    ],
  },
  {
    component: PaymentMethodMobilePage,
    path: paths.PAYMENT_METHOD_MOBILE,
    exact: true,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
  },
  {
    component: SSOContainer,
    exact: true,
    isPrivate: false,
    path: paths.WEB_SSO,
    crumb: () => ({ isCrumb: false }),
  },
  {
    component: RegistrationBranch,
    path: paths.REGISTRATION,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
    routes: [
      {
        component: () => {
          window.open(TERMS_CONDITIONS_LINK)
          return null
        },
        path: paths.TERMS_CONDITIONS,
        isPrivate: false,
        exact: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_TERMS_CONDITIONS, isExclude: false }),
      },
      {
        component: RegistrationContainer,
        path: paths.REGISTRATION,
        isPrivate: false,
        exact: true,
        crumb: () => ({ isCrumb: false }),
      },
    ],
  },
  {
    component: DownloadAppPage,
    path: paths.MIGRATE_VERIFY,
    exact: false,
    isPrivate: false,
    crumb: () => ({ isCrumb: true, name: paths.CRUMB_MIGRATE_VERIFY, isExclude: false }),
  },
  {
    component: UnsubscribeContainer,
    path: paths.EMAIL_UNSUBSCRIBE,
    exact: true,
    isPrivate: false,
    crumb: () => ({ isCrumb: false }),
  },
  {
    component: DownloadAppPage,
    path: paths.GRADUATION,
    exact: true,
    isPrivate: false,
    crumb: () => ({ isCrumb: true, name: paths.CRUMB_GRADUATION, isExclude: false }),
  },
  {
    component: VisitSurveyPage,
    path: paths.SURVEYS,
    exact: true,
    isPrivate: false,
    crumb: () => ({ isCrumb: true, name: paths.CRUMB_SURVEYS, isExclude: false }),
  },
  {
    component: withIdleAlert(NotFoundPage),
    path: paths.PAGE_NOT_FOUND,
    crumb: () => ({ isCrumb: true, name: paths.CRUMB_PAGE_NOT_FOUND, isExclude: false }),
  },
  {
    component: TermsAndConditionsPage,
    path: paths.TERMS_AND_CONDITIONS,
    exact: true,
    isPrivate: true,
  },
  {
    component: DrawerBranch,
    path: paths.DASHBOARD,
    isPrivate: true,
    crumb: () => ({ isCrumb: true, name: paths.CRUMB_DASHBOARD, isExclude: true }),
    routes: [
      {
        component: withIdleAlert(DashboardContainer),
        path: paths.DASHBOARD,
        exact: true,
        isPrivate: true,
        crumb: () => ({ isCrumb: false }),
      },
      {
        component: VideoContainer,
        path: paths.VISIT_VIDEO_VIEW,
        exact: true,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_VISIT_VIDEO_VIEW, isExclude: false }),
      },
      {
        component: ChatVisitPage,
        path: paths.VISIT_CHAT_VIEW,
        exact: true,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_VISIT_CHAT_VIEW, isExclude: false }),
      },
      {
        component: withIdleAlert(VideoFeedbackContainer),
        path: paths.VISIT_FEEDBACK,
        exact: true,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_VISIT_FEEDBACK, isExclude: false }),
      },
      {
        component: AccountBranch,
        path: paths.ACCOUNT,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(AccountGeneralContainer),
            path: paths.ACCOUNT_GENERAL,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_GENERAL, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountServiceContainer),
            path: paths.ACCOUNT_SERVICES,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_SERVICES, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountGeneralDependentContainer),
            path: paths.ACCOUNT_DEPENDENTS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_DEPENDENTS, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountSecurityContainer),
            path: paths.ACCOUNT_SECURITY,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_PASSWORD, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountBillingContainer),
            path: paths.ACCOUNT_BILLING,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_BILLING, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountDependentContainer),
            path: paths.ACCOUNT_DEPENDENT_VIEW,
            exact: true,
            isPrivate: true,
            crumb: () => ({
              isCrumb: true,
              name: '',
              prevCrumb: paths.CRUMB_ACCOUNT_DEPENDENTS,
              isExclude: false,
            }),
          },
          {
            component: withIdleAlert(AccountDependentContainer),
            path: paths.ACCOUNT_DEPENDENTS_ADD,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_DEPENDENTS_ADD, isExclude: false }),
          },
          {
            component: withIdleAlert(AccountMedicalContainer),
            path: paths.ACCOUNT_MEDICAL,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_ACCOUNT_MEDICAL, isExclude: false }),
          },
        ],
      },
      {
        component: ExploreBranch,
        path: paths.EXPLORE,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_EXPLORE, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(SelfCareContainer),
            path: paths.EXPLORE,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(ExploreComponentContainer),
            path: paths.EXPLORE_COMPONENT,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(ExploreCategoryContainer),
            path: paths.EXPLORE_CATEGORY,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(ExploreJourneyContainer),
            path: paths.EXPLORE_JOURNEY,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
        ],
      },
      {
        component: HelpCenterBranch,
        path: paths.HELP_CENTER,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_HELP_CENTER, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(HelpCenterContainer),
            path: paths.HELP_CENTER,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
        ],
      },
      {
        component: CommunityBranch,
        path: paths.COMMUNITY,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_COMMUNITY, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(CommunityContainer),
            path: paths.COMMUNITY,
            exact: false,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
        ],
      },
      {
        component: DashboardMedicalBranch,
        path: paths.MEDICAL_MEMBER,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_MEMBER, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(MedicalGeneralContainer),
            path: paths.MEDICAL_OVERVIEW,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_OVERVIEW, isExclude: false }),
          },
          {
            component: withIdleAlert(FamilyHistoryContainer),
            path: paths.MEDICAL_FAMILY,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_FAMILY, isExclude: false }),
          },
          {
            component: withIdleAlert(MedicalConditionsContainer),
            path: paths.MEDICAL_CONDITIONS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_CONDITIONS, isExclude: false }),
          },
          {
            component: withIdleAlert(PrimaryCareContainer),
            path: paths.MEDICAL_PROVIDERS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_PROVIDERS, isExclude: false }),
          },
          {
            component: withIdleAlert(PrimaryCareEditContainer),
            path: paths.MEDICAL_PROVIDER,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(MedicalAllergiesContainer),
            path: paths.MEDICAL_ALLERGIES,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_ALLERGIES, isExclude: false }),
          },
          {
            component: withIdleAlert(MedicalAllergiesEditContainer),
            path: paths.MEDICAL_ALLERGY,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(MedicalMedicationsContainer),
            path: paths.MEDICAL_MEDICATIONS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_MEDICATIONS, isExclude: false }),
          },
          {
            component: withIdleAlert(MedicalGeneralContainer),
            path: paths.MEDICAL_GENERAL,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_GENERAL, isExclude: false }),
          },
          {
            component: withIdleAlert(MedicalLifestyleContainer),
            path: paths.MEDICAL_LIFESTYLE,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_MEDICAL_LIFESTYLE, isExclude: false }),
          },
        ],
      },
      {
        component: DashboardPendingVisitBranch,
        path: paths.PENDING_VISITS,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_PENDING_VISITS, isExclude: false }),
        routes: [
          {
            component: withIdleAlert(AddPendingVisitContainer),
            path: paths.ADD_PENDING_VISIT,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(MedicalWizardContainer),
            path: paths.MEDICAL_WIZARD,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_HEALTH_PROFILE, isExclude: false }),
          },
        ],
      },
      {
        component: SuccessBranch,
        path: paths.SUCCESS,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: false }),
        routes: [
          {
            component: SuccessPage,
            path: paths.SUCCESS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_SUCCESS, isExclude: false }),
          },
          {
            component: withIdleAlert(SuccessCoursesPage),
            path: paths.SUCCESS_COURSES,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_SUCCESS_COURSES, isExclude: false }),
          },
          {
            component: withIdleAlert(SuccessCoarseDetailsContainer),
            path: paths.SUCCESS_COURSE_DETAILS,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_SUCCESS_COURSES, isExclude: false }),
          },
          {
            component: withIdleAlert(SuccessCourseComponentContainer),
            path: paths.SUCCESS_COURSE_COMPONENT,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: true, name: paths.CRUMB_SUCCESS_COURSES, isExclude: false }),
          },
        ],
      },
      {
        component: MessagesBranch,
        path: paths.MESSAGES,
        exact: false,
        isPrivate: true,
        crumb: () => ({ isCrumb: true, name: paths.CRUMB_MESSAGES, isExclude: false }),
        routes: [
          {
            component: () => <Redirect to={paths.VISIT_MESSAGES} />,
            path: paths.MESSAGES,
            exact: true,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(MessagesCenterContainer),
            path: paths.VISIT_MESSAGES,
            exact: false,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
          {
            component: withIdleAlert(MessagesCenterContainer),
            path: paths.TASK_MESSAGES,
            exact: false,
            isPrivate: true,
            crumb: () => ({ isCrumb: false }),
          },
        ],
      },
      // {
      //   component: withIdleAlert(SendFeedbackContainer)),
      //   path: paths.SEND_FEEDBACK,
      //   exact: true,
      //   isPrivate: true,
      //   crumb: () => ({ isCrumb: true, name: paths.CRUMB_SEND_FEEDBACK, isExclude: false }),
      // },
    ],
  },
  {
    component: DashboardRedirect,
    path: '*',
  },
]

export default routeTree
