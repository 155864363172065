import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import * as ProviderSelectors from 'redux/selectors/providers'
import withForm from 'hoc/withForm'
import ProviderHeader from 'components/visit-creation/provider-info/ProviderHeader'
import TestIds from 'util/TestIds'
import FilterIcon from '@ui-components-3/icons/lib/regular/BarsFilter'
import CircleInfoIcon from '@ui-components-3/icons/lib/regular/CircleInfo'
import TherapyProviderFilters from '../TherapyProviderFilters/TherapyProviderFilters'
import { FiltersSchema } from 'schemas/NewVisitProviderSchema'
import {
  RaceFilterMap,
  availableTherapyVisitFiltersOptions,
  getPrintableShortProviderName,
  isTherapyRelated,
  mapTherapyVisitFiltersToDiagnosticExpertise,
  providerDefaultFilters,
} from 'util/provider'
import isEmpty from 'lodash/isEmpty'
import NonTherapyProviderFilters from '../NonTherapyProviderFilters'
import { getRequiredAriaLabel } from 'util/form'
import { phoneFormat } from 'util/formatters'
import { SUPPORT_NUMBER } from 'util/support'
import Loading from '@ui-components-3/ui/lib/components/Loading'
import AngleRightIcon from '@ui-components-3/icons/lib/regular/AngleRight'
import ProviderInfoDialog from 'components/visit-creation/provider-info/ProviderInfoDialog'
import { clearProviders } from 'redux/slices/providers'
import { fetchAvailableProviders } from 'redux/thunks/providers'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { isSuccess } from 'util/helpers'
import useFeatureFlagToggle from 'components/feature-flag/useFeatureFlagToggle'
import { getVisitProviderIds } from 'redux/selectors/visits'
import { setOrUpdateFilters, setSelectedCategory } from 'redux/slices/providerFilters'
import { TcFF } from 'components/feature-flag/flags'
import { getMemberAge } from 'redux/selectors/members'
import { useMinorConsent } from 'hooks/useMinorConsent'

const renderProviderCards = ({
  labelId,
  list,
  handleProviderSelection,
  onDetailsClick,
  selectedProviderId,
  indexOffset = 0,
  isSuccess = false,
}) => (
  <RadioCardsGroup
    columnWidth={280}
    name="providerId"
    aria-labelledby={labelId}
    className="mt-xxs"
    options={list}
    controlPlacement="rt"
    onChange={(e) => handleProviderSelection(e.target.value)}
    value={selectedProviderId}
    getOptionValue={(provider) => provider.providerId}
    getLabelProps={(provider) => ({
      className: 'flex flex-col justify-between',
      suffix: (
        <button
          type="button"
          className="btn-small btn-neutral-borderless w-full justify-between rounded-none bg-neutral-200 text-neutral-600"
          onClick={onDetailsClick(provider)}
          aria-label={`See more details of ${getPrintableShortProviderName(provider, isSuccess)}`}
        >
          See more details
          <AngleRightIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      ),
    })}
    getOptionLabel={(provider) => {
      const index = list.indexOf(provider) + indexOffset
      return (
        <ProviderHeader
          provider={provider}
          data-testid={TestIds.newVisit.input.provider(index)}
          infoTestId={TestIds.newVisit.label.providerInfo(index)}
          isSuccess={isSuccess}
        />
      )
    }}
  />
)

const notShownEthnicityOptions = ['I do not wish to disclose']

// convert this file to typescript and fix the errors that come up
export const ProviderSelectionForm = withForm((props) => {
  const { isValid, handleSubmit, setFieldValue, pendingVisit, values } = props

  const successVisit = useMemo(() => {
    return isSuccess(pendingVisit.visitType)
  }, [pendingVisit])

  const isScheduledMedicalVisit = pendingVisit.scheduledType === 'scheduled' && pendingVisit.visitType === 'medical'

  const dispatch = useDispatch()

  const [isMinorExpertNeeded, setIsMinorExpertNeeded] = useState(false)
  const { getConsentTypeAndStatus } = useMinorConsent(pendingVisit.memberId)

  const isMinorConsentPhase2FlagOn = useFeatureFlagToggle(TcFF.tcMinorConsentPhase2)
  const isServiceLineRoutingFlagOn = useFeatureFlagToggle('tcServiceLineRouting')
  const memberAge = useSelector(getMemberAge(pendingVisit?.memberId))

  const shouldSeeMinorExpertProviders =
    isMinorConsentPhase2FlagOn && !isServiceLineRoutingFlagOn && memberAge && memberAge < 18

  const getConsentData = useCallback(async () => {
    const { isSelfConsentGranted, isParentConsentGranted } = await getConsentTypeAndStatus(
      pendingVisit.visitType,
      pendingVisit.location,
    )

    setIsMinorExpertNeeded(isSelfConsentGranted && !isParentConsentGranted)
  }, [getConsentTypeAndStatus, pendingVisit.visitType, pendingVisit.location])

  const handleFetchAvailableProviders = useCallback(
    async (filters) => {
      if (isEmpty(pendingVisit)) return null
      dispatch(clearProviders())
      return dispatch(
        fetchAvailableProviders(
          pendingVisit.memberId,
          pendingVisit.visitType,
          pendingVisit.location,
          filters,
          pendingVisit.locationCountry,
          isMinorExpertNeeded,
        ),
      )
    },
    [dispatch, isMinorExpertNeeded],
  )

  const sectionedProviders = useSelector(ProviderSelectors.getSectionedAvailableProviders(pendingVisit))
  const allAvailableProviders = useSelector(ProviderSelectors.getAvailableProviders(pendingVisit))
  const allVisits = useSelector(getVisitProviderIds)
  const previousProviders = useMemo(
    () => allAvailableProviders.filter((provider) => allVisits.includes(provider.providerId)),
    [allAvailableProviders, allVisits],
  )
  const [expandedProvider, setExpandedProvider] = useState(null)
  const providerInfoDialogRef = useRef(null)
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const [selectedValuesCount, setSelectedValuesCount] = useState(0)
  const filterRef = useRef(null)
  const isTcProviderFilterV2FlagOn = useFeatureFlagToggle('tcProviderFilterV2')
  const toggleIsFiltersVisible = useCallback((_isVisible) => () => setIsFiltersVisible(!!_isVisible), [])

  const moreThanSevenProviders = useMemo(() => allAvailableProviders.length > 7, [allAvailableProviders])

  const availableProviderExpertise = useMemo(() => {
    const allProviderExpertise = allAvailableProviders
      .map((provider) => provider.profile?.diagnosticExpertise)
      .filter(Boolean)
    return Array.from(new Set(allProviderExpertise.flat()))
  }, [allAvailableProviders])

  const availableProviderGenders = useMemo(() => {
    const genderOptions = ['Male', 'Female', 'Another Gender Identity']

    const availableGenders = new Set(
      allAvailableProviders.map((provider) => {
        const gender = provider?.profile?.genderIdentity
        return genderOptions.includes(gender) ? gender : 'Another Gender Identity'
      }),
    )

    availableGenders.add('All')

    return Array.from(availableGenders)
  }, [allAvailableProviders])

  const availableProviderEthnicity = useMemo(() => {
    const allProviderRace = allAvailableProviders
      .map((provider) => provider.profile?.raceEthnicity)
      .filter((race) => RaceFilterMap.some((filter) => filter.value === race))

    const flattenedProviderRaces = Array.from(new Set(allProviderRace.flat()))
    const availableRaceFilterTitles = RaceFilterMap.filter((race) => flattenedProviderRaces.includes(race.value)).map(
      (race) => race.label,
    )
    return availableRaceFilterTitles.push('All') && availableRaceFilterTitles
  }, [allAvailableProviders])

  const checkConditions = useMemo(() => {
    const presentConditions = []
    availableProviderExpertise.forEach((condition) => {
      const conditionFound = Object.values(mapTherapyVisitFiltersToDiagnosticExpertise).some((expertiseArray) =>
        expertiseArray.includes(condition || ''),
      )
      if (conditionFound && condition) {
        presentConditions.push(condition)
      }
    })
    return presentConditions
  }, [availableProviderExpertise])

  const availableExpertiseFilters = useMemo(
    () => availableTherapyVisitFiltersOptions(checkConditions),
    [checkConditions],
  )

  const hideFilterOption = useCallback(
    (option) => {
      if (option === 'Expertise') {
        return availableExpertiseFilters.length < 3
      } else if (option === 'Gender') {
        return availableProviderGenders.length < 1
      } else if (option === 'Race') {
        return availableProviderEthnicity.length < 3
      }
      return false
    },
    [availableExpertiseFilters, availableProviderEthnicity, availableProviderGenders],
  )

  const hasRecommendedProviders = useMemo(
    () => (sectionedProviders?.recommended?.length ?? 0) > 0,
    [sectionedProviders],
  )

  const bestMatchResults = useSelector(ProviderSelectors.getBestMatchProvider(pendingVisit, values.filters))
  const closeMatchResults = useSelector(
    ProviderSelectors.getCloseMatchProvider(pendingVisit, values.filters, bestMatchResults),
  )
  const remainingAvailableResults = useSelector(
    ProviderSelectors.getRemainingAvailableProvider(pendingVisit, bestMatchResults, closeMatchResults),
  )
  const defaultFilters = providerDefaultFilters(pendingVisit)

  useEffect(() => {
    if (shouldSeeMinorExpertProviders) {
      getConsentData()
    }
  }, [getConsentData, shouldSeeMinorExpertProviders])

  useEffect(() => {
    handleFetchAvailableProviders()
  }, [isMinorExpertNeeded])

  const getProviderFilterCategory = useCallback(
    (providerId) => {
      if (!selectedValuesCount) {
        return 'Available'
      }
      if (bestMatchResults.some((provider) => provider.providerId === providerId)) {
        return 'Best Match'
      }
      if (closeMatchResults.some((provider) => provider.providerId === providerId)) {
        return 'Close Match'
      }
      return 'Additional Providers'
    },
    [bestMatchResults, closeMatchResults],
  )

  const handleProviderSelection = (providerId) => {
    setFieldValue('providerId', providerId)
    const filterCategorySelectedFrom = getProviderFilterCategory(providerId)
    dispatch(setSelectedCategory(filterCategorySelectedFrom))
    providerInfoDialogRef.current?.close()
    setExpandedProvider(null)
  }

  const handleProviderDetailsClick = useCallback(
    (provider) => (e) => {
      e.stopPropagation()
      if (!expandedProvider || expandedProvider.providerId !== provider.providerId) {
        setExpandedProvider(provider)
        providerInfoDialogRef.current?.open()
      }
    },
    [setExpandedProvider, expandedProvider],
  )

  useEffect(() => {
    setTimeout(() => {
      if (!values.providerId) {
        if (values.providerName) {
          setFieldValue('providerName', undefined)
        }
      } else {
        if (allAvailableProviders.length) {
          const provider = allAvailableProviders.find((provider) => provider.providerId === values.providerId)
          if (provider) {
            setFieldValue('providerName', getPrintableShortProviderName(provider, successVisit))
            setFieldValue('providerTimezone', provider.timezone)
          } else {
            console.error('no provider found')
          }
        }
      }
    }, 100)
  }, [allAvailableProviders, setFieldValue, successVisit, values.providerId, values.providerName])

  const clearFilters = () => {
    setFieldValue('filters', defaultFilters)
    handleFetchAvailableProviders()
    toggleIsFiltersVisible(false)()
  }

  const applyFilters = (filters) => {
    setFieldValue('filters', filters)
    dispatch(setOrUpdateFilters(filters))
    toggleIsFiltersVisible(false)()
  }

  const RenderFilters = useMemo(
    () => (pendingVisit && isTherapyRelated(pendingVisit) ? TherapyProviderFilters : NonTherapyProviderFilters),
    [pendingVisit],
  )

  const filterButtons = [
    <button
      key="filter-btn"
      onClick={toggleIsFiltersVisible(true)}
      className={`btn-small w-36 rounded-xl ${
        selectedValuesCount || isFiltersVisible ? 'btn-neutral' : 'btn-primary-outlined'
      }`}
      type="button"
    >
      <FilterIcon className="h-5 w-5" aria-hidden="true" />
      Filter {!!selectedValuesCount && `(${selectedValuesCount})`}
    </button>,
  ]

  const idPrefix = useId()
  const allProvidersLabelId = idPrefix + 'all'
  const previousProvidersLabelId = idPrefix + 'previous'
  const availableProvidersLabelId = idPrefix + 'available'
  const bestMatchProviderLabelId = idPrefix + 'best_match'
  const closeMatchProviderLabelId = idPrefix + 'close_match'
  const remainingAvailableProviderLabelId = idPrefix + 'remaining_available'

  const shouldShowFilterUi = useMemo(() => {
    return isTherapyRelated(pendingVisit) && isTcProviderFilterV2FlagOn
  }, [pendingVisit, isTcProviderFilterV2FlagOn])

  const availableMinusPrevious = useMemo(() => {
    if (shouldShowFilterUi && previousProviders.length > 0) {
      return sectionedProviders?.general.filter((provider) => !previousProviders.includes(provider))
    }
    return sectionedProviders?.general
  }, [sectionedProviders?.general, previousProviders, isTcProviderFilterV2FlagOn, pendingVisit])

  return (
    <>
      <form className="flex w-full flex-1 flex-col" onSubmit={handleSubmit}>
        <div className="max-w-[1180px] pb-6">
          <div className="mb-4 flex flex-col justify-between md:flex-row">
            <div className="mb-2 shrink-0">
              <h2
                className="typography-h5 m-0 text-xl text-neutral-800"
                data-testid={TestIds.newVisit.label.providerTitle}
                id={allProvidersLabelId}
              >
                Select {successVisit ? 'a coach' : 'an available provider'}
              </h2>
              {!hasRecommendedProviders && !isScheduledMedicalVisit && !successVisit && (
                <p className="typography-body font-medium text-neutral-600">Sorted by first available</p>
              )}
            </div>
            {/* TODO filters -> uncomment next line */}
            {shouldShowFilterUi && moreThanSevenProviders && (
              <div className="flex flex-wrap items-center">{filterButtons}</div>
            )}
          </div>
          {!!selectedValuesCount && (
            <div className="mb-8 flex justify-between border-b-[1px] border-b-neutral-300 pb-2">
              <span className="typography-h5 text-neutral-800">Results</span>
              <button
                type="button"
                className="typography-body-l text-primary-600 btn-primary-borderless btn-sm pr-1"
                onClick={() => {
                  filterRef.current?.resetForm()
                  clearFilters()
                }}
              >
                Clear Filter
              </button>
            </div>
          )}

          {allAvailableProviders.length === 0 ? (
            props.isLoading ? (
              <div className="flex justify-center">
                <Loading>
                  <p className="typography-body-l text-neutral">Locating Available Providers</p>
                </Loading>
              </div>
            ) : (
              <p className="typography-body-l text-neutral">No available provider matches your criteria.</p>
            )
          ) : (
            <div className="flex flex-col" aria-label={getRequiredAriaLabel('Provider')}>
              {previousProviders.length > 0 && !selectedValuesCount && shouldShowFilterUi && (
                <>
                  <span
                    className="typography-body font-medium uppercase text-neutral-600"
                    id={previousProvidersLabelId}
                  >
                    Previous Providers
                  </span>
                  <div className="pb-6">
                    {renderProviderCards({
                      labelId: previousProvidersLabelId,
                      list: previousProviders,
                      handleProviderSelection,
                      onDetailsClick: handleProviderDetailsClick,
                      selectedProviderId: values.providerId,
                      indexOffset: 0,
                    })}
                  </div>
                </>
              )}
              {bestMatchResults.length > 0 && !!selectedValuesCount && isTcProviderFilterV2FlagOn && (
                <>
                  <div className="relative flex items-center">
                    <div
                      className="typography-body font-medium uppercase text-neutral-500"
                      id={bestMatchProviderLabelId}
                    >
                      BEST MATCH
                    </div>
                    <div className="group relative">
                      <CircleInfoIcon className="text-primary-600 mb-1 ml-2 cursor-pointer" aria-hidden="true" />
                      <div className="typography-body-l absolute bottom-full left-0 z-10 mb-2 hidden w-max rounded-md bg-black p-4 font-medium text-white group-hover:block">
                        Providers match all of the selected filter criteria.
                      </div>
                    </div>
                  </div>

                  <div className="pb-6">
                    {renderProviderCards({
                      labelId: bestMatchProviderLabelId,
                      list: bestMatchResults,
                      handleProviderSelection,
                      onDetailsClick: handleProviderDetailsClick,
                      selectedProviderId: values.providerId,
                      indexOffset: 0,
                    })}
                  </div>
                </>
              )}
              {closeMatchResults.length > 0 && !!selectedValuesCount && isTcProviderFilterV2FlagOn && (
                <>
                  <div className="relative flex items-center">
                    <div
                      className="typography-body font-medium uppercase text-neutral-500"
                      id={closeMatchProviderLabelId}
                    >
                      CLOSE MATCH
                    </div>
                    <div className="group relative">
                      <CircleInfoIcon className="text-primary-600 mb-1 ml-2 cursor-pointer" aria-hidden="true" />
                      <div className="typography-body-l absolute bottom-full left-0 z-10 mb-2 hidden w-max rounded-md bg-black p-4 font-medium text-white group-hover:block">
                        Providers match at least one of the selected filter criteria.
                      </div>
                    </div>
                  </div>
                  <div className="pb-6">
                    {renderProviderCards({
                      labelId: closeMatchProviderLabelId,
                      list: closeMatchResults,
                      handleProviderSelection,
                      onDetailsClick: handleProviderDetailsClick,
                      selectedProviderId: values.providerId,
                      indexOffset: 0,
                    })}
                  </div>
                </>
              )}
              {remainingAvailableResults.length > 0 && !!selectedValuesCount && isTcProviderFilterV2FlagOn && (
                <>
                  <span
                    className="typography-body font-medium uppercase text-neutral-600"
                    id={remainingAvailableProviderLabelId}
                  >
                    Additional Providers
                  </span>
                  <div>
                    {renderProviderCards({
                      labelId: remainingAvailableProviderLabelId,
                      list: remainingAvailableResults,
                      handleProviderSelection,
                      onDetailsClick: handleProviderDetailsClick,
                      selectedProviderId: values.providerId,
                      indexOffset: 0,
                    })}
                  </div>
                </>
              )}

              {!selectedValuesCount && (
                <div data-testid={TestIds.newVisit.view.availableProviders}>
                  <span
                    className="typography-body pt-4 font-medium uppercase text-neutral-600"
                    id={availableProvidersLabelId}
                  >
                    AVAILABLE
                  </span>
                  {renderProviderCards({
                    labelId: hasRecommendedProviders ? availableProvidersLabelId : allProvidersLabelId,
                    list: !successVisit ? availableMinusPrevious : allAvailableProviders,
                    handleProviderSelection,
                    onDetailsClick: handleProviderDetailsClick,
                    selectedProviderId: values.providerId,
                    indexOffset: 0,
                    isSuccess: successVisit,
                  })}
                </div>
              )}
            </div>
          )}
        </div>

        {!successVisit && (
          <div className="typography-body mb-6 font-medium text-neutral-800">
            Need help finding an appointment? Call{' '}
            <a className="text-link-primary font-semibold" href={`tel:+${SUPPORT_NUMBER}`}>
              {phoneFormat(SUPPORT_NUMBER)}
            </a>{' '}
            to connect with our care coordinators.
          </div>
        )}
        {props.children({ disabled: !isValid || !values.providerId })}
      </form>
      <ProviderInfoDialog
        ref={providerInfoDialogRef}
        onSelect={() => handleProviderSelection(expandedProvider?.providerId)}
        provider={expandedProvider}
        isSuccess={successVisit}
        onClose={() => setExpandedProvider(null)}
      />
      {isTcProviderFilterV2FlagOn && moreThanSevenProviders && (
        <RenderFilters
          onCancel={toggleIsFiltersVisible(false)}
          onSubmit={applyFilters}
          initialValues={values.filters || defaultFilters}
          schema={FiltersSchema}
          isVisible={isFiltersVisible}
          onUpdateValuesCount={(count) => setSelectedValuesCount(count)}
          filterRef={filterRef}
          hideFilterOption={hideFilterOption}
          availableExpertise={availableExpertiseFilters}
          availableGenders={availableProviderGenders}
          availableEthnicity={availableProviderEthnicity}
          defaultFilters={defaultFilters}
          selectedCount={selectedValuesCount}
        />
      )}
    </>
  )
})

ProviderSelectionForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  enableReinitialize: PropTypes.bool,
  dirty: PropTypes.bool,
  isValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  pendingVisit: PropTypes.object,
  children: PropTypes.func.isRequired,
}

export default ProviderSelectionForm
