import * as Yup from 'yup'
import catchApiFailure from '../catch-api-error'
import ApiFactory, { ApiTypes } from '../../index'
import configBuilder from '../config-builder'

const api = ApiFactory.getApi(ApiTypes.member)

const RequestSchema = Yup.object().shape({
  location: Yup.string().label('Location').required(),
  visitType: Yup.string().label('Visit Type').required(),
  params: Yup.object()
    .shape({
      filter: Yup.object()
        .shape({
          memberId: Yup.string().label('Member ID').required(),
          ethnicity: Yup.array().of(Yup.string()).nullable(),
          gender: Yup.string().oneOf(['M', 'F', 'X']).nullable(),
          'languages.abbrev': Yup.array().of(Yup.string()).nullable(),
          specialtyFocus: Yup.array().of(Yup.string()).nullable(),
          'profile.community_expertise': Yup.string().notRequired().nullable(),
        })
        .required(),
    })
    .required(),
})

export const isValid = async (params) => await RequestSchema.isValid(params)
export const validate = async (params) => RequestSchema.validate(params)
export const url = ({ location, visitType }) => `/v1/providers/available/${location}/${visitType}`
export const execute = async (params) => api.get(url(params), configBuilder(params)).catch(catchApiFailure)
